<template>
  <div class="bookings-table">
    <!-- NEW BOOKING BUTTON AND SEARCH -->
    <b-row
      v-if="showSearch || showButtons"
      class="mb-2 d-flex flex-wrap"
      :class="{
        'justify-content-between': showButtons,
        'justify-content-end': !showButtons,
      }"
    >
      <b-col v-if="showButtons" cols="3" md="auto" class="mb-1 mb-md-0 d-flex">
        <b-button
          variant="primary"
          block
          class="d-flex justify-content-center"
          @click.prevent="onButtonClicked"
        >
          <feather-icon icon="PlusIcon" class="d-block d-md-none" />
          <span class="d-none d-md-inline">{{ $t("Nova reserva") }}</span>
        </b-button>
        <b-button
          variant="primary"
          class="d-flex justify-content-center ml-2"
          @click.prevent="onRefreshClicked"
        >
          <feather-icon icon="RefreshCwIcon" />
        </b-button>
        <b-button
          v-if="isAdmin"
          variant="primary"
          class="d-flex justify-content-center ml-1"
          @click.prevent="
            $router.push({ name: 'foravila-bookings-fictitious' })
          "
        >
          <feather-icon icon="UmbrellaIcon" />
        </b-button>
      </b-col>
      <b-col
        v-if="showSearch"
        cols="9"
        md="auto"
        class="mb-1 mb-md-0"
        :class="{
          'justify-content-between': showButtons,
          'justify-content-end': !showButtons,
        }"
      >
        <b-form-input
          id="filterInput"
          ref="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          debounce="1000"
          autofocus
          :disabled="isBusy"
        />
      </b-col>
    </b-row>

    <!-- BOOKINGS TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          ref="bookingsTable"
          striped
          borderless
          responsive
          class="position-relative"
          :busy.sync="isBusy"
          :per-page="itemsPerPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="fetchBookings"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="filter"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-img
                id="loading-gif"
                class="my-5"
                fluid-grow
                :src="
                  require('@/assets/foravila/images/gifs/house-loading-1.gif')
                "
              />
            </div>
          </template>

          <!-- Column: date -->
          <template #cell(date)="data">
            <span class="text-nowrap">
              {{ bookingDate(data.item) }}
            </span>
          </template>

          <!-- Column: localizator -->
          <template #cell(localizator)="data">
            <b-booking-localizator
              :localizator="data.value"
              :source="data.item.source"
              :highlighted="data.item.highlighted"
              :fictitious="data.item.fictitious"
              :cancelled="data.item.cancelled"
            />
          </template>

          <!-- Column: accommodation -->
          <template #cell(accommodation)="data">
            <b-accommodation
              :uuid="data.item.accommodationUuid"
              :name="data.value"
            />
          </template>

          <!-- Column: client -->
          <template #cell(client)="data">
            <b-client :uuid="data.item.clientUuid" :name="data.value" />
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-status-badge :status="data.item.status" />
          </template>

          <!-- Column: checkin -->
          <template #cell(checkin)="data">
            {{ bookingFormattedDate(data.item.checkin) }}
          </template>

          <!-- Column: checkout -->
          <template #cell(checkout)="data">
            {{ bookingFormattedDate(data.item.checkout) }}
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!isBusy"
      v-if="bookingsCount > pageOptions[0]"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start"
      >
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per pàgina')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="itemsPerPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-end"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="itemsPerPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BButton,
  VBTooltip,
  BImg,
} from "bootstrap-vue";
import { notifyError } from "@/utils/methods";
import {
  formatDateObjectToDatabaseDate,
  formatDateObjectToDate,
} from "@/utils/formatters";
import BStatusBadge from "@foravila-core/components/b-status-badge/BStatusBadge.vue";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";
import BClient from "@foravila-core/components/b-client/BClient.vue";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BBookingLocalizator,
    BAccommodation,
    BClient,
    BStatusBadge,
    BImg,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    showButtons: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isBusy: false,
      sortBy: "date",
      sortDesc: true,
      itemsPerPage: 50,
      pageOptions: [5, 10, 50, 100, 500],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      fields: [
        { key: "date", label: this.$t("Data"), sortable: true },
        { key: "localizator", label: this.$t("Nº reserva") },
        { key: "accommodation", label: this.$t("Allotjament") },
        { key: "client", label: this.$t("Client") },
        { key: "checkin", label: this.$t("Entrada"), sortable: true },
        { key: "checkout", label: this.$t("Sortida"), sortable: true },
        { key: "status", label: this.$t("Estat"), sortable: true },
      ],
      selectedBooking: null,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    bookingsCount() {
      return this.$store.getters["bookings/count"];
    },
    today() {
      return formatDateObjectToDatabaseDate(new Date());
    },
  },
  watch: {
    isBusy(busy) {
      if (!busy) {
        this.$nextTick(() => {
          //! this needs to be done in the nextTick because the input
          //! can not be focused if it is disabled, and the input is
          //! disabled while the data is being fetched
          this.$refs.filterInput.focus();
        });
      }
    },
  },
  mounted() {
    this.$refs.filterInput.focus();
  },
  methods: {
    fetchBookings(ctx, callback) {
      this.$store
        .dispatch("bookings/fetchFilteredBookings", {
          pagination: true,
          itemsPerPage: ctx.perPage,
          page: ctx.currentPage,
          searchText: ctx.filter || "",
          sort: {
            key: ctx.sortBy,
            order: ctx.sortDesc ? "desc" : "asc",
          },
        })
        .then((response) => {
          callback(this.mapTableBookingsArray(response));
          this.totalRows = this.bookingsCount;
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.description")
          )
        );

      return null;
    },
    mapTableBookingsArray(bookings) {
      if (!bookings.length) return null;
      return bookings.map((booking) => ({
        uuid: booking.uuid || this.$t("No definit"),
        date: new Date(booking.date) || this.$t("No definit"),
        localizator: booking.localizator || this.$t("No definit"),
        status: booking.status || this.$t("No definit"),
        cancelled: booking.cancelled || false,
        source: booking.source || this.$t("No definit"),
        client: booking.client?.fullName || this.$t("No definit"),
        clientUuid: booking.client?.uuid || null,
        accommodation: booking.accommodation?.name || this.$t("No definit"),
        accommodationUuid: booking.accommodation?.uuid || null,
        checkin: new Date(booking.checkin) || this.$t("No definit"),
        checkout: new Date(booking.checkout) || this.$t("No definit"),
        highlighted: booking.highlighted || false,
        fictitious: booking.fictitious || false,
      }));
    },
    onButtonClicked() {
      this.$router.push({ name: "foravila-add-booking" });
    },
    onRefreshClicked() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("bookings/importBookings", {
          startDate: this.today,
          endDate: this.today,
          importType: "BOOKING_DATE",
        })
        .catch(() =>
          notifyError(
            "Reserves no importada",
            "Les reserves no s'han pogut importar correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => {
          this.$refs.bookingsTable.refresh();
          this.$store.dispatch("app/setLoading", false);
        });
    },
    bookingDate(booking) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return (
        formatDateObjectToDate(booking.date, this.$i18n.locale, formatting) ||
        "No definit"
      );
    },
    bookingFormattedDate(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      };
      return (
        formatDateObjectToDate(date, this.$i18n.locale, formatting) ||
        "No definit"
      );
    },
  },
};
</script>

<style lang="scss">
.bookings-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
  #loading-gif {
    max-width: 350px;
  }
}
</style>
