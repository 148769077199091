<template>
  <div
    class="bookings-mobile-list-item bg-light cursor-pointer p-2 border rounded"
    @click="onBookingSelected"
  >
    <div
      class="d-flex justify-content-between align-items-center mb-1 border-bottom pb-1"
    >
      <b-booking-localizator
        :localizator="localizator"
        :source="booking.source"
        :highlighted="booking.highlighted"
        :fictitious="booking.fictitious"
        :cancelled="booking.cancelled"
        class="text-nowrap font-weight-bold"
      />
      <b-status-badge v-if="showStatusBadge" :status="status" />
    </div>
    <h5 v-if="showAccommodationName">
      {{ accommodationName }}
    </h5>
    <h6 class="mb-50">
      {{ clientName }}
    </h6>
    <div class="mt-1">
      <div>
        <feather-icon icon="ClockIcon" class="mr-1" />
        <span class="small">{{ bookingDate }}</span>
      </div>
      <div v-if="showCheckin && checkinText">
        <feather-icon icon="LogInIcon" class="mr-1" />
        <span class="small">{{ checkinText }}</span>
      </div>
      <div v-if="showCheckout && checkoutText">
        <feather-icon icon="LogOutIcon" class="mr-1" />
        <span class="small">{{ checkoutText }}</span>
      </div>
      <div v-if="showNights">
        <feather-icon icon="MoonIcon" class="mr-1" />
        <span class="small">{{ nights }}</span>
      </div>
      <div v-if="showGuests">
        <feather-icon icon="UsersIcon" class="mr-1" />
        <span class="small">{{ guests }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBookingStatusName,
  getBookingStatusColor,
  getGuestsText,
  getNightsText,
} from "@/utils/methods";
import { formatDateStringToDate } from "@/utils/formatters";
import BStatusBadge from "@foravila-core/components/b-status-badge/BStatusBadge.vue";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";

export default {
  components: {
    BStatusBadge,
    BBookingLocalizator,
  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
    hideAccommodationName: {
      type: Boolean,
      default: false,
    },
    hideStatusBadge: {
      type: Boolean,
      default: false,
    },
    departureBadge: {
      type: Boolean,
      default: false,
    },
    hideCheckin: {
      type: Boolean,
      default: false,
    },
    hideCheckout: {
      type: Boolean,
      default: false,
    },
    hideGuests: {
      type: Boolean,
      default: false,
    },
    hideNights: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getBookingStatusName,
      getBookingStatusColor,
    };
  },
  computed: {
    showStatusBadge() {
      return !this.hideStatusBadge;
    },
    showCheckin() {
      return !this.hideCheckin;
    },
    showCheckout() {
      return !this.hideCheckout;
    },
    showGuests() {
      return !this.hideGuests;
    },
    showNights() {
      return !this.hideNights;
    },
    showAccommodationName() {
      return !this.hideAccommodationName;
    },
    status() {
      return this.booking?.status || null;
    },
    localizator() {
      return this.booking?.localizator || null;
    },
    accommodationName() {
      return this.booking?.accommodation?.name || null;
    },
    clientName() {
      return this.booking?.client?.fullName || null;
    },
    bookingDate() {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return (
        formatDateStringToDate(
          this.booking.date,
          this.$i18n.locale,
          formatting
        ) || null
      );
    },
    checkin() {
      return this.booking?.checkin
        ? formatDateStringToDate(this.booking.checkin)
        : null;
    },
    checkinTime() {
      return this.booking?.checkinTime || null;
    },
    checkinText() {
      const checkinText = [this.checkin];
      if (this.checkinTime) checkinText.push(this.checkinTime);
      return checkinText.length ? `${checkinText.join(" ")}` : null;
    },
    checkout() {
      return this.booking?.checkout
        ? formatDateStringToDate(this.booking.checkout)
        : null;
    },
    checkoutTime() {
      return this.booking?.checkoutTime || null;
    },
    checkoutText() {
      const checkoutText = [this.checkout];
      if (this.checkoutTime) checkoutText.push(this.checkoutTime);
      return checkoutText.length ? `${checkoutText.join(" ")}` : null;
    },
    guests() {
      return getGuestsText(
        {
          adults: this.booking?.adults || null,
          children: this.booking?.children || null,
          babies: this.booking?.babies || null,
        },
        this.$i18n.locale
      );
    },
    nights() {
      return getNightsText(this.booking?.nights, this.$i18n.locale);
    },
  },
  methods: {
    onBookingSelected() {
      this.$router.push({
        name: "foravila-booking-view",
        params: { localizator: this.booking.localizator },
      });
    },
  },
};
</script>
