<template>
  <b-card>
    <bookings-mobile-list v-if="isMobile" show-buttons show-search />
    <bookings-table v-else show-buttons show-search />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import BookingsTable from "@/views/bookings/list/components/BookingsTable.vue";
import BookingsMobileList from "@/views/bookings/list/components/BookingsMobileList.vue";

export default {
  components: {
    BCard,
    BookingsTable,
    BookingsMobileList,
  },
  computed: {
    isMobile() {
      return this.$store.getters["app/isMobile"];
    },
  },
};
</script>
