<template>
  <div class="bookings-mobile-list">
    <!-- NEW BOOKING BUTTON AND SEARCH -->
    <b-row v-if="showSearch || showButtons" class="mb-1">
      <b-col v-if="showSearch" cols="12" class="mb-1">
        <b-form-input
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          debounce="1000"
          autofocus
          :disabled="loadingBookings"
        />
      </b-col>

      <template v-if="showButtons">
        <b-col cols="8">
          <b-button block variant="primary" @click.prevent="onButtonClicked">
            <feather-icon icon="PlusIcon" />
            <span>{{ $t("Nova reserva") }}</span>
          </b-button>
        </b-col>
        <b-col cols="4">
          <!-- primary -->
          <b-dropdown
            split
            block
            right
            variant="primary"
            @click.prevent="onRefreshClicked"
          >
            <template #button-content>
              <feather-icon icon="RefreshCwIcon" />
            </template>
            <b-dropdown-item
              v-if="isAdmin"
              class="dropdown-icon-wrapper"
              @click.prevent="
                $router.push({ name: 'foravila-bookings-fictitious' })
              "
            >
              Fictícies
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </template>
    </b-row>

    <!-- BOOKINGS LIST -->
    <b-row>
      <b-col
        v-if="loadingBookings"
        cols="12"
        class="d-flex justify-content-center"
      >
        <b-img
          id="loading-gif"
          class="my-5"
          fluid-grow
          :src="require('@/assets/foravila/images/gifs/house-loading-1.gif')"
        />
      </b-col>

      <template v-else>
        <b-col
          v-for="booking in bookings"
          :key="`booking-${booking.uuid}`"
          md="12"
        >
          <bookings-mobile-list-item
            :booking="booking"
            class="mb-1"
            hide-nights
            hide-guests
          />
        </b-col>
      </template>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!loadingBookings"
      v-if="bookingsCount > pageOptions[0]"
      class="d-flex justify-content-between align-items-center mt-2"
    >
      <b-col cols="12" class="mb-50 d-flex justify-content-center">
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per pàgina')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="perPageSelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="itemsPerPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4" lg="3" class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="itemsPerPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BButton,
  VBTooltip,
  BImg,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { notifyError } from "@/utils/methods";
import { formatDateObjectToDatabaseDate } from "@/utils/formatters";
import BookingsMobileListItem from "@/views/bookings/list/components/BookingsMobileListItem.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BImg,
    BookingsMobileListItem,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    showButtons: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      itemsPerPage: 10,
      pageOptions: [5, 10, 50, 100, 500],
      totalRows: 1,
      currentPage: 1,
      filter: null,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    loadingBookings() {
      return this.$store.getters["bookings/loading"];
    },
    bookings() {
      return this.$store.getters["bookings/bookings"];
    },
    bookingsCount() {
      return this.$store.getters["bookings/count"];
    },
    today() {
      return formatDateObjectToDatabaseDate(new Date());
    },
  },
  watch: {
    currentPage() {
      this.fetchBookings();
    },
    itemsPerPage() {
      this.fetchBookings();
    },
    filter() {
      this.fetchBookings();
    },
  },
  created() {
    this.fetchBookings();
  },
  methods: {
    fetchBookings() {
      this.$store
        .dispatch("bookings/fetchFilteredBookings", {
          pagination: true,
          itemsPerPage: this.itemsPerPage,
          page: this.currentPage,
          searchText: this.filter || "",
          sort: {
            key: "date",
            order: "desc",
          },
        })
        .then(() => {
          this.totalRows = this.bookingsCount;
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.description")
          )
        );

      return null;
    },
    mapTableBookingsArray(bookings) {
      if (!bookings.length) return null;
      return bookings.map((booking) => ({
        uuid: booking.uuid || this.$t("No definit"),
        date: new Date(booking.date) || this.$t("No definit"),
        localizator: booking.localizator || this.$t("No definit"),
        status: booking.status || this.$t("No definit"),
        cancelled: booking.cancelled || false,
        source: booking.source || this.$t("No definit"),
        client: booking.client?.fullName || this.$t("No definit"),
        accommodation: booking.accommodation?.name || this.$t("No definit"),
        checkin: new Date(booking.checkin) || this.$t("No definit"),
        checkout: new Date(booking.checkout) || this.$t("No definit"),
        highlighted: booking.highlighted || false,
      }));
    },
    onButtonClicked() {
      this.$router.push({ name: "foravila-add-booking" });
    },
    onRefreshClicked() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("bookings/importBookings", {
          startDate: this.today,
          endDate: this.today,
          importType: "BOOKING_DATE",
        })
        .catch(() =>
          notifyError(
            "Reserves no importada",
            "Les reserves no s'han pogut importar correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => {
          this.$refs.bookingsTable.refresh();
          this.$store.dispatch("app/setLoading", false);
        });
    },
  },
};
</script>

<style lang="scss">
.bookings-mobile-list {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
  #loading-gif {
    max-width: 350px;
  }
}
</style>
